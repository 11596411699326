//const URL = `http://34.172.171.98:5001/api/`; // localhost
const URL = `/api/`; // for production with nginx
//const URL = "http://192.168.1.12:5001/api/"; // for production with nginx
// const URL = "http://localhost:5001/api/";
// const URL = "https://forms.rabinovichfoundation.org.il/api/"; // idk


export function POST(route, body = {}) {
  const token = localStorage.getItem("token");

  return fetch(`${URL}${route}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token,
    },
    credentials: 'include',
    body: JSON.stringify(body),
  });
}

export function GET(route) {
  const token = localStorage.getItem("token");

  return fetch(`${URL}${route}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token,
    },
  });
}

export async function REQUEST(method, route, body) {
  const token = localStorage.getItem("token");
  console.log(JSON.stringify(body));
  return fetch(`${URL}${route}`, {
    method,
    headers: {
      "Content-Type": "application/json",
      token,
    },
    body: JSON.stringify(body)
  });
}

export function PUT(route, body = {}) {
  const token = localStorage.getItem("token");

  return fetch(`${URL}${route}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      token,
    },
    body: JSON.stringify(body),
  });
}

export function POST_FORM_DATA(route, body = new FormData()) {
  const token = localStorage.getItem("token");

  return fetch(`${URL}${route}`, {
    method: "POST",
    body,
    headers: { token },
  });
}

export function DELETE(route) {
  const token = localStorage.getItem("token");

  return fetch(`${URL}${route}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token,
    },
  });
}
