import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { GET, POST_FORM_DATA, DELETE, POST } from "../../../../API";
import { LoadingScreenWithHeader } from "../../../../comps/loading";
import Button from "../../../../comps/button";
import Error from "../../../../comps/Error";
import Dialog from "../../../../comps/dialog";
import { AiOutlineArrowRight } from "react-icons/ai";
import FormStep1 from "./parts/Step1";
import FormStep2 from "./parts/Step2";
import FormStep3 from "./parts/Step3";
import FormStep4 from "./parts/Step4";
import styles from "./style.module.scss";
import Success from "./parts/success";
import { useParams, useNavigate } from "react-router-dom";

const steps_html = [
  "מילוי פרטים",
  "העלאת קבצים - ניתן לעלות קבצי PDF בלבד",
  "השלמת פרטים",
  "חתימה ושליחה",
];

const Errors = {
  400: "המידע שהזנת אינו תקין",
  401: "מספר פלאפון לא תקני - ניתן רק מספרים ישראלים",
  402: "כתובת מייל לא תקנית",
  403: "כמות קבצים גדולה מדי",
  404: "שדה זה הינו חובה",
  405: "מספר זהות/ ח.פ מכיל רק 9 ספרות",
  406: "המספר חרג ממגבל התווים",
  407: "ניתן להכניס רק מספרים",
  408: "פורמט לינק לא תקני - נסה לתת לינק שונה",
  409: "חרגת ממגבלת התווים",
  410: "לא ניתן להכניס תאריך בעבר",
  411: "ניתן להכניס רק מספרים פסיק ונקודה",
  412: "בעקבות בחריתך בשדה קודם, שזה זה הינו שדה חובה",
  413: "הקובץ גדול מדי, אפשר עד 8MB",
  415: "סוג קובץ לא נתמך",
};

export default function Form() {
  const [cookies, _setCookie, removeCookie] = useCookies(["form_session"]);
  const { category } = useParams();
  const [isError, setIsError] = useState();
  const [isLoading, setIsLoading] = useState();
  const GoTo = useNavigate();
  const [Headline, setHeadline] = useState("");

  const [isCompleted, setIsCompleted] = useState(false);
  const [crrStep, setCrrStep] = useState(1);
  const [crrFields, setCrrFields] = useState([]);
  const [fileLink, setFileLink] = useState();

  const [theDialog, setTheDialog] = useState();

  useEffect(() => {
    if (!cookies.form_session || !cookies.session_type) rejectCookie();

    if (category != cookies.session_type) {
      GoTo(`/${cookies.session_type}/open-dates/form`);
    }

    get_fields();
  }, [cookies]);

  const rejectCookie = () => {
    removeCookie("form_session");
    removeCookie("session_type");

    window.location.href = `/${category}`;
  };

  const get_fields = async (ran = 0) => {
    setCrrFields([]);
    if (ran > 4) return window.location.reload();
    setIsLoading(true);
    try {
      const response = await GET(
        `request/form?form_session=${cookies.form_session}`
      );

      if (response.status === 202) return get_fields(++ran);

      if (response.status === 302) return rejectCookie();

      const { fields, step, name, error, completed, id, signed, file_link } =
        await response.json();

      if (error) throw new Error();

      setCrrFields(formatFields(fields || {}));
      setHeadline(name);
      setCrrStep(step);
      setFileLink(file_link);

      if (completed) {
        setIsCompleted(id);
      }

      if (signed) {
        submitStep();
      }
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const formatFields = (json) => {
    return Object.keys(json)
      .map((key) => {
        let item = {
          order: json[key].order,
          name: key,
          placeholder:
            json[key].hebrew_name + `${json[key].require ? "*" : "-שדה רשות"}`,
          label:
            json[key].hebrew_name + `${json[key].require ? "*" : "-שדה רשות"}`,
          required: json[key].require,
          type: json[key].type,
          status: "",
          helper_text: "",
          value: json[key].value || "",
          comment: json[key].comment || "",
          dependencies: json[key].dependencies || [],
          condition_value: json[key].condition_value || "",
          condition: json[key].condition || "",
          displayInput: json[key].condition_value ? false : true,
        };
        if (item.type === "option") {
          item.dropdown = json[key].option;
          item.value = item.value || item.dropdown[0];
        }
        if (item.type === "title") {
          item.placeholder = json[key].hebrew_name;
          item.color = json[key].color;
        }
        if (json[key].max) {
          item.type = "file";
          item.max = json[key].max;
          item.files = json[key].files || [];
        } else item.value = item.value || "";

        if (item.condition_value) {
          item.placeholder = json[key].hebrew_name + "*";
          item.label = json[key].hebrew_name + "*";
          // check if the condition is true if so display the input
          if (json[item.condition].value == item.condition_value) {
            item.displayInput = true;
          }
        }

        return item;
      })
      .sort((a, b) => a.order - b.order);
  };

  const setFields = (name, value) => {
    setCrrFields((prv) => {
      const crrField = prv.find((item) => item.name === name);
      const fieldDependencies = crrField.dependencies.map((item) => item.field);

      return prv.map((field) => {
        if (field.name === name) {
          field.value = value;
          field.status = "";
          field.helper_text = "";
        } else if (fieldDependencies.includes(field.name)) {
          field.status = "";
          field.helper_text = "";
          if (field.condition_value === value) {
            field.displayInput = true;
          } else {
            field.displayInput = false;
          }
        }
        return field;
      });
    });
  };

  const convertToFileObject = async (url, key, fileIndex) => {
    return await fetch(url).then(async (response) => {
      const blob = await response.blob();
      const file = new File([blob], `${key}-${fileIndex}.pdf`, {
        type: "application/pdf",
      });

      setCrrFields((prv) =>
        prv.map((col) => {
          if (col.name === key) {
            col.files[fileIndex] = file;
          }
          return col;
        })
      );
      return file;
    });
  };

  const uploadFile = (name, file, index) => {
    setCrrFields((prv) =>
      prv.map((field) => {
        if (field.name === name) {
          field.files = field.files.map((objFile, i) => {
            if (typeof objFile === "string" && objFile.length) {
              convertToFileObject(objFile, field.name, i);
            }
            if (index === i) {
              objFile = file;
            }
            return objFile;
          });
          field.status = "";
          field.helper_text = "";
        }
        return field;
      })
    );
  };

  const removeRequest = () => {
    setTheDialog();

    (async function () {
      const response = await DELETE(
        `request/remove-session?form_session=${cookies.form_session}`
      );
      if (response.status === 200) {
        removeCookie("form_session");
        removeCookie("session_type");
      } else setIsError(true);
    })();
  };

  const displayError = (errors) => {
    setCrrFields((prv) =>
      prv.map((field) => {
        if (errors[field.name]?.length) {
          field.status = "error";
          field.helper_text = errors[field.name].map(
            (errStatus) => Errors[errStatus]
          );
        }
        return field;
      })
    );
  };

  const sendDialog = (text, onYes) => {
    setTheDialog({
      text,
      onYes,
      onNo: setTheDialog,
    });
  };

  const HandleWantToDelete = () => {
    sendDialog("האם אתה בטוח שברצונך לבטל?", removeRequest);
  };

  const goBack = () => {
    (async function () {
      try {
        const response = await POST(
          `request/previous-step?form_session=${cookies.form_session}`
        );

        if (response.status === 200) {
          window.location.reload();
        }
      } catch {
        setIsError(true);
      }
    })();
  };

  const submitStep = async ({ doc = [] }) => {
    setIsLoading(true);
    let formdata = new FormData();
    formdata.append("EASTER", "EGG");
    crrFields.forEach((field) => {
      if (field.type === "file") {
        [...field.files].forEach((file) => {
          if (typeof file !== "string") {
            formdata.append(field.name, file);
          }
        });
      } else formdata.append(field.name, field.value);
    });

    doc.forEach((item) => {
      formdata.append(item.name, item.value);
    });

    try {
      const response = await POST_FORM_DATA(
        `request/save-step?&form_session=${cookies.form_session}`,
        formdata
      );
      const json = await response.json();

      if (response.status === 425) {
        return submitStep();
      }

      if (response.ok) {
        if (json.completed) {
          return setIsCompleted(json.id);
        }
        return get_fields();
      }
      if (Object(json.error) === json.error) {
        return displayError(json.error);
      }
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  if (isCompleted) return <Success request_id={isCompleted} />;
  if (isLoading) return <LoadingScreenWithHeader />;
  if (isError) return <Error />;
  if (theDialog) return <Dialog {...theDialog} />;
  return (
    <div className={styles.Container}>
      <div>
        {crrStep >= 2 && (
          <Button options={"x_small hover_dark"} onClick={() => goBack()}>
            <AiOutlineArrowRight className={styles.icon} /> חזור לשלב הקודם
          </Button>
        )}
      </div>
      <div className={styles.Headline}>
        <label>על מנת לבחור תחום הגשה שונה יש לבטל את ההגשה הנוכחית</label>
        <Button
          options={"x_small hover_dark"}
          onClick={() => HandleWantToDelete()}
        >
          בטל הגשה
        </Button>
        <h1>{Headline}</h1>
        <span>בכל שלב ניתן לחזור לשלב הקודם ולבצע שינוים</span>
        <span>{steps_html[crrStep - 1]}</span>
      </div>
      {theDialog && <Dialog {...theDialog} />}
      {crrStep === 1 ? (
        <FormStep1
          fields={crrFields}
          setFields={setFields}
          submitStep={submitStep}
        />
      ) : crrStep === 2 ? (
        <FormStep2
          fields={crrFields}
          uploadFile={uploadFile}
          submitStep={submitStep}
        />
      ) : crrStep === 3 ? (
        <FormStep3
          fields={crrFields}
          setFields={setFields}
          submitStep={submitStep}
        />
      ) : crrStep === 4 ? (
        <FormStep4 fileLink={fileLink} submitStep={submitStep} />
      ) : null}
    </div>
  );
}
