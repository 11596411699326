// This is the top-level container component responsible for managing the state of the form configuration. 
// It will contain the FormBuilder component.

// This component will manage the state of the form configuration (stored as JSON). 
// It will handle adding/removing fields, updating field properties, and saving the form configuration.


import React, { useState, useEffect } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import uuid from 'react-uuid';
import { isKeyUnique, isDependencyValid, getErrorMessage, validateFormFields } from './utils/formValidation';
import { transformDataToFormField, transformDataToFileFormField } from './utils/transformField';
import TextBuilder from './components/TextBuilder';
import FilesBuilder from './components/FilesBuilder';
import { textJson, fileJson } from './services/formService'
import {GET, PUT, REQUEST} from "../../../API";
import {useParams, useSearchParams} from "react-router-dom";
import styles from "./style.module.scss";
import { useNavigate } from "react-router-dom";

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const FormBuilderContainer = () => {
    const [searchParams] = useSearchParams();
    const initialFormFields = []; // Define initial state for resetting
    const [textFields, setTextFields] = useState(initialFormFields);
    const [fileFields, setFileFields] = useState(initialFormFields);
    const [data, setData] = useState({});
    const [mainTypes, setMainTypes] = useState([]);
    const formId = searchParams.get('form_id');
    const navigate = useNavigate();
    const { category } = useParams();

    useEffect(() => {
        GET(`art-type/${category}/all-arts`).then(async (fetchedData) => {
            const json = await fetchedData.json();
            setMainTypes(json["arts"]);
        });
    }, []);

    useEffect(() => {
      if (formId) {
        GET(`form/${formId}`).then(async (fetchedData) => {
            fetchedData = await fetchedData.json();
            // Assuming fetchedData is an object with a property that's an object of field definitions
            const textObjects = Object.entries(fetchedData.text_fields); // If fetchedData directly contains the field objects
            const fileObjects = Object.entries(fetchedData.files_fields); // If fetchedData directly contains the field objects
            const translatedTextFields = textObjects.map(([key, field]) => transformDataToFormField(key, field));
            const translatedFilesFields = fileObjects.map(([key, field]) => transformDataToFileFormField(key, field));

            setTextFields(translatedTextFields);
            setFileFields(translatedFilesFields);
            setData(fetchedData);
        })
      }
    }, [formId]);


    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const items = reorder(
            textFields,
            result.source.index,
            result.destination.index
        );

        setTextFields(items.map((item, index) => ({ ...item, order: index + 1 }))); // Update order based on new index
    };

    const addField = (newFieldData = {}) => {
        const defaultFieldData = {
            type: 'text', // Set the default type to "text"
            key: `field-${textFields.length}`, // Generate a default key, ensure it's unique in your actual implementation
            hebrewLabel: '', // Default Hebrew label if not provided
            required: false, // Default requirement state
            dependsOn: null, // Default dependency
            // Any other default properties you need
        };
    
        // Combine the provided field data with the defaults. Provided values override defaults.
        const finalFieldData = { ...defaultFieldData, ...newFieldData };
    
        // Check for key uniqueness
        if (!isKeyUnique(textFields, finalFieldData.key)) {
            alert(getErrorMessage("uniqueKey"));
            return; // Stop execution if the key is not unique
        }
    
        // Check for valid dependency
        if (finalFieldData.dependsOn && !isDependencyValid(textFields, finalFieldData.dependsOn)) {
            alert(getErrorMessage("validDependency"));
            return; // Stop execution if the dependency is not valid
        }
    
        // Create the new field with a unique ID and the combined field data
        const newField = {
            id: uuid(), // Generate a unique ID for the field
            ...finalFieldData, // Spread in the combined new field data
            order: textFields.length + 1, // Set the order based on the current number of fields
        };
    
        // Update the form fields state to include the new field
        setTextFields([...textFields, newField]);
    };

    const addFileField = (newFieldData = {}) => {
      const defaultFieldData = {
          key: `field-${fileFields.length}`, // Generate a default key, ensure it's unique in your actual implementation
          hebrewLabel: '', // Default Hebrew label if not provided
          required: false, // Default requirement state
          max: 1, // Default max files
          private: false, // Default private
          // Any other default properties you need
      };
  
      // Combine the provided field data with the defaults. Provided values override defaults.
      const finalFieldData = { ...defaultFieldData, ...newFieldData };
  
      // Check for key uniqueness
      if (!isKeyUnique(fileFields, finalFieldData.key)) {
          alert(getErrorMessage("uniqueKey"));
          return; // Stop execution if the key is not unique
      }
    
      // Create the new field with a unique ID and the combined field data
      const newField = {
          id: uuid(), // Generate a unique ID for the field
          ...finalFieldData, // Spread in the combined new field data
          order: fileFields.length + 1, // Set the order based on the current number of fields
      };
  
      // Update the form fields state to include the new field
      setFileFields([...fileFields, newField]);
    };

    const removeField = (fieldId) => {
        const updatedFields = textFields.filter(field => field.id !== fieldId).map((field, index) => ({
            ...field,
            order: index + 1 // Recalculate order on removal
        }));
        setTextFields(updatedFields);
    };

    const removeFileField = (fieldId) => {
      const updatedFields = fileFields.filter(field => field.id !== fieldId).map((field, index) => ({
          ...field,
          order: index + 1 // Recalculate order on removal
      }));
      setFileFields(updatedFields);
    };


    const updateFieldProperties = (fieldId, updatedProperties) => {
        // Perform validation if the 'key' property is being updated
        if ('key' in updatedProperties && !isKeyUnique(textFields, updatedProperties.key, fieldId)) {
            // If the new key is not unique, alert the user and do not proceed with the update
            alert(getErrorMessage("uniqueKey"));
            return;
        }

        // Proceed with the update if validation passes
        const updatedFields = textFields.map(field => {
            if (field.id === fieldId) {
                if ('option' in updatedProperties) {
                    updatedProperties.option = updatedProperties.option.split(',')
                    console.log(updatedProperties);
                }
                return { ...field, ...updatedProperties };
            }
            return field;
        });
        setTextFields(updatedFields);
    };
    
    const updateFileFieldProperties = (fieldId, updatedProperties) => {
      // Perform validation if the 'key' property is being updated
      if ('key' in updatedProperties && !isKeyUnique(fileFields, updatedProperties.key, fieldId)) {
          // If the new key is not unique, alert the user and do not proceed with the update
          alert(getErrorMessage("uniqueKey"));
          return;
      }
  
      // Proceed with the update if validation passes
      const updatedFields = fileFields.map(field => {
          if (field.id === fieldId) {
              return { ...field, ...updatedProperties };
          }
          return field;
      });
      setFileFields(updatedFields);
    };

    const saveForm = () => {
        const { isValid, invalidFields } = validateFormFields(textFields);
        if (!isValid) {
            // Handle invalid form state. For example, alert the user.
            alert("Please ensure all fields have a 'type', 'key' and a 'Hebrew label'.");
            console.log("Invalid fields:", invalidFields); // Log or handle invalid fields appropriately
            return; // Abort the save operation
        }
        // Assuming exportFormJson is an asynchronous function saving the form
        let newData = data;
        newData.text_fields = textJson(textFields);
        newData.files_fields = fileJson(fileFields);
        newData.main_type = newData.main_type ?? mainTypes[0]?.id ?? "";

        const route = formId ? `form/${formId}` : `form`;
        const method = formId ? "PUT" : "POST";
        REQUEST(method, route, newData)
            .then((results) => {
                navigate({
                    pathname: '/forms',
                });
                console.log(results)
            })
            .catch((error) => {
                console.error('Error saving form:', error);
            });
    };

    return (
        <div className={styles.Container + ' formsContainer'} >
            <h1 className={styles.Headline}>בניית טופס</h1>
            <DragDropContext onDragEnd={onDragEnd}>
              <div className={styles.CardsWrraper}>
                  <label htmlFor={'main_type'}>עבור תחום:</label>
                  <select
                      id={'main_type'}
                      value=""
                      onChange={(e) => setData({...data, main_type: e.target.value})}
                  >
                      {mainTypes.map((type) => {
                          return <option key={type.id} value={type.id}>{type.name}</option>
                      })}
                  </select>
              </div>
              <div className={styles.CardsWrraper}>
                <label htmlFor={'field-name'}>שם:</label>
                <input
                    id={'field-name'}
                    type="text"
                    value={data.name}
                    onChange={(e) => setData({...data, name: e.target.value})}
                    className='style_inp__eLH4y'
                />
              </div>
              <TextBuilder
                  formFields={textFields}
                  addField={addField}
                  removeField={removeField}
                  updateFieldProperties={updateFieldProperties}
                  saveForm={saveForm}
              />
              <FilesBuilder
                  formFields={fileFields}
                  addField={addFileField}
                  removeField={removeFileField}
                  updateFieldProperties={updateFileFieldProperties}
                  saveForm={saveForm}
              />
            </DragDropContext>
          <button onClick={saveForm} className='style_btn__APPaZ'>שמירה</button>
        </div>
    );
};

export default FormBuilderContainer;
