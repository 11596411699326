// This component represents individual form fields. 
// It will be used by the FormBuilder component to render each field and handle interactions such as drag-and-drop.

// This component will render individual form fields based on their type. 
// It will handle interactions such as drag-and-drop for reordering fields.

import React from 'react';
import styles from "../style.module.scss";

const TextField = ({ field, formFields, removeField, updateFieldProperties }) => {
  const handleRemoveField = () => {
    removeField(field.id);
  };

  const handleUpdateField = (property, value) => {
    const updatedProperties = { [property]: value };
    updateFieldProperties(field.id, updatedProperties);
  };

  return (
    <div className={styles.inputField}>
      <h3 className={styles.titleField}>{field.hebrewLabel}</h3>
      <div className={styles.CardsWrraper}>
        <label htmlFor={`field-${field.key}-type`}>סוג שדה:</label>
        <select
          id={`field-${field.key}-type`}
          value={field.type}
          onChange={(e) => handleUpdateField('type', e.target.value)}
          className='style_inp__eLH4y'
        >
          <option value="text">טקסט</option>
          <option value="textarea">טקסט ארוך</option>
          <option value="option">שדה בחירה</option>
          <option value="email">אימייל</option>
          <option value="number">מספר</option>
          <option value="file">קובץ</option>
          <option value="date">תאריך</option>
          <option value="radio">כפתור רדיו</option>
          <option value="time">זמן</option>
          <option value="money">כסף</option>
          <option value="tel">טלפון</option>
          <option value="url">URL</option>
          <option value="title">כותרת</option>
        </select>
      </div>
      {
        field.type === "option" || field.type === "radio" ?
          <div className={styles.CardsWrraper}>
            <label htmlFor={`field-${field.key}-option`}>אפשרויות בחירה (הפרדה עם פסיק ,):</label>
            <input
              id={`field-${field.key}-option`}
              type="text"
              value={field.option}
              onChange={(e) => handleUpdateField('option', e.target.value)}
              className='style_inp__eLH4y'
            />
          </div> : null
      }
      <div className={styles.CardsWrraper}>
        <label htmlFor={`field-${field.key}-key`}>מזהה שדה:</label>
        <input
          id={`field-${field.key}-key`}
          type="text"
          value={field.key}
          onChange={(e) => handleUpdateField('key', e.target.value)}
          className='style_inp__eLH4y'
        />
      </div>
      <div className={styles.CardsWrraper}>
        <label htmlFor={`field-${field.key}-hebrew-label`}>שם בעברית:</label>
        <input
          id={`field-${field.key}-hebrew-label`}
          type="text"
          value={field.hebrewLabel}
          onChange={(e) => handleUpdateField('hebrewLabel', e.target.value)}
          className='style_inp__eLH4y'
        />
      </div>
      <div className={styles.CardsWrraper}>
        <label htmlFor={`field-${field.key}-required`}>שדה חובה:</label>
        <input
          id={`field-${field.key}-required`}
          type="checkbox"
          checked={field.required}
          onChange={(e) => handleUpdateField('required', e.target.checked)}
        />
      </div>
      <div className={styles.CardsWrraper}>
        <label htmlFor={`field-${field.key}-dependsOn`}>תלות:</label>
        <select
          id={`field-${field.key}-dependsOn`}
          value={field.dependsOn || ''}
          onChange={(e) => handleUpdateField('dependsOn', e.target.value)}
          className='style_inp__eLH4y'
        >
          <option value="">ללא תלות</option>
          {formFields.filter(f => f.id !== field.id).map((f) => (
            <option key={f.id} value={f.key}>{f.key}</option>
          ))}
        </select>
      </div>
      {
        field.dependsOn ? 
          <div className={styles.CardsWrraper}>
            <label htmlFor={`field-${field.key}-condition`}>תנאי:</label>
            <input
              id={`field-${field.key}-condition`}
              type="text"
              value={field.condition}
              onChange={(e) => handleUpdateField('condition', e.target.value)}
              className='style_inp__eLH4y'
            />
          </div> :
          null
      }
      <div className={styles.CardsWrraper}>
        <button onClick={handleRemoveField} className={styles.Btn + " style_btn__APPaZ.style_small__nlrzy"}>מחק שדה</button>
      </div>
    </div>
  );
};

export default TextField;
